<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-select v-model="search.client_brand_code" clearable @change="Search" placeholder="品牌">
            <el-option
              v-for="item in prolist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.schema" clearable @change="Search" placeholder="终端编号">
            <el-option
              v-for="item in appcodelist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addRule">增加</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="内容页编号">
        <template v-slot="scope">
          <span>{{ contentCodelist[scope.row.content_code] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="国家简码">
        <template v-slot="scope">
          <span>{{ scope.row.country_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="品牌名称">
        <template v-slot="scope">
          <span>{{ Procodelist[scope.row.client_brand_code] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="终端编号">
        <template v-slot="scope">
          <span>{{ appName[scope.row.schema] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="软件版本编号">
        <template v-slot="scope">
          <span>{{ scope.row.version_app_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <el-select v-model="scope.row.status" @change="ChangeStatus(scope.row)">
            <el-option label="下架" :value="1"></el-option>
            <el-option label="测试" :value="2"></el-option>
            <el-option label="上架" :value="3"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <span class="el-icon-top" style="cursor: pointer" @click="UpOrder(scope.row.code)"></span>
          <span class="el-icon-bottom" style="margin:0 5px;cursor: pointer" @click="DownOrder(scope.row.code)"></span>
          <el-button type="primary" size="small" @click="editRule(scope.row.code)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteRule(scope.row.code)">删除</el-button>
          <router-link style="margin-left:10px" :to="'/iot/contentmanage/rule/content/'+search.content_code+'&'+ ruleType +'&'+scope.row.code">
            <el-button type="info" size="small">内容页</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'内容规则'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item v-if="countryShow" label="国家" label-width="120px">
          <el-select v-model="form.country_code" multiple placeholder="请选择">
            <el-option
              v-for="item in countryOption"
              :key="item.code"
              :label="item.chinese"
              :value="item.code+''">
            </el-option>
          </el-select>
          <!-- <vue-json-editor :show-btns="false" :mode="'code'" id="editor_holder" :expandedOnStart="true" v-model="form.country_code" @json-change="onJsonChange"></vue-json-editor> -->
        </el-form-item>
        <el-form-item v-if="proShow" label="品牌编号" label-width="120px">
          <el-select v-model="form.client_brand_code" placeholder="">
            <el-option
              v-for="item in prolist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="schemaShow" label="终端编号" label-width="120px">
          <el-select v-model="form.schema" placeholder="">
            <el-option
              v-for="item in appcodelist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="versionShow" label="软件版本编号" label-width="120px">
          <el-select v-model="form.version_app_code" multiple placeholder="请选择">
            <el-option
              v-for="item in Softlist"
              :key="item.code"
              :label="item.package_name"
              :value="item.code">
            </el-option>
          </el-select>
          <!-- <vue-json-editor :show-btns="false" :mode="'code'" id="editor_holder" :expandedOnStart="true" v-model="form.version_app_code" @json-change="onAppVersionChange"></vue-json-editor> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import vueJsonEditor from 'vue-json-editor'
export default {
  name: '',
  mixins: [],
  components: {
    vueJsonEditor
  },
  props: {},
  data () {
    return {
      title: '',
      countryShow: true,
      proShow: true,
      schemaShow: true,
      versionShow: true,
      search: {
        page: 1,
        limit: 10,
        content_code: '',
        client_brand_code: '',
        schema: '',
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      form: {
        code: '',
        content_code: '',
        country_code: '',
        client_brand_code: '',
        schema: '',
        version_app_code: '',
      },
      rules: {
        country_code: [{ required: true, message: '请输入国家简码', trigger: 'blur' }],
        client_brand_code: [{ required: true, message: '请输入产品编号', trigger: 'blur' }],
        schema: [{ required: true, message: '请输入终端编号', trigger: 'blur' }],
        version_app_code: [{ required: true, message: '请输入软件版本编号', trigger: 'blur' }],
      },
      edit: false,
      ruleType: 0,
      countryOption: [],
      appVersionOption: [],
      Softlist: [],
      appcodelist:[],
      prolist:[],
      contentCodelist: {},
      Procodelist: {},
      appName: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    ChangeStatus (item) {
      this.api.RuleStatus({
        code: item.code,
        status: item.status
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('状态修改成功')
          return
        }
        this.$message.error('状态修改失败' + res.data.msg)
      })
    },
    ChangeOrder (item,direction) {
      this.api.ContentRuleOrder({
        code: item,
        action: +direction
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('排序成功')
          this.Search()
          return
        }
        this.$message.error('排序失败' + res.data.msg)
      })
    },
    UpOrder (item) {
      this.ChangeOrder(item,2)
    },
    DownOrder (item) {
      this.ChangeOrder(item,1)
    },
    GetcontentCode(){
      this.contentCodelist = {}
      this.api.ContentList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            this.contentCodelist[item.code] = item.name
          });
          this.GetProcode()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetProcode(){
      this.api.ProductList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            this.Procodelist[item.code] = item.name
          })
          this.GetAppcode()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetAppcode () {
      this.appName = {}
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.appcodelist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.appName[item.code] = item.name
          })
          this.GetProList()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetProList () {
      this.api.ProductList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.prolist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetAppVersion() {
      this.api.SoftList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.Softlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    getCountryCode () {
      this.api.CountryList({
        page:1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.countryOption = res.data.data.data
          this.GetAppVersion()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search(){
      this.api.ContentRuleList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    onJsonChange (value) {// 数据改变时触发
      this.form.country_code = JSON.stringify(value)
      console.log(this.form.country_code,value)
    },
    onAppVersionChange (value) {// 数据改变时触发
      this.form.version_app_code = JSON.stringify(value)
    },
    addRule () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
      this.getCountryCode()
    },
    editRule(item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.ContentRuleDetail({code: item}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          this.form.country_code = JSON.parse(res.data.data.country_code)
          this.form.version_app_code = JSON.parse(res.data.data.version_app_code)
          this.getCountryCode()
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    deleteRule(item) {
      this.$confirm('是否删除该功能点?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ContentRuleDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update () {
      this.form.content_code = this.$route.params.code.split('&')[0]
      this.api.ContentRuleUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm () {
      this.update()
    },
    editconfirm () {
      this.update()
    },
    goback (){
      this.$router.push('/iot/contentmanage')
    }
  },
  filters: {},
  mounted () {
    this.search.content_code = this.$route.params.code.split('&')[0]
    this.ruleType = this.$route.params.code.split('&')[1]
    this.Search()
    if(this.ruleType == 1){
      this.countryShow = false
      this.proShow = false
      this.schemaShow = true
      this.versionShow = false
    } else if (this.ruleType == 2) {
      this.countryShow = false
      this.proShow = true
      this.schemaShow = false
      this.versionShow = false
    } else if (this.ruleType == 3){
      this.countryShow = false
      this.proShow = true
      this.schemaShow = true
      this.versionShow = false
    } else if (this.ruleType == 4){
      this.countryShow = false
      this.proShow = false
      this.schemaShow = true
      this.versionShow = true
    } else if (this.ruleType == 5){
      this.countryShow = true
      this.proShow = false
      this.schemaShow = true
      this.versionShow = false
    } else if (this.ruleType == 100) {
      this.countryShow = true
      this.proShow = true
      this.schemaShow = true
      this.versionShow = true
    }
  },
  created () {
    this.GetcontentCode()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
